import _ from 'lodash';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Button, Card, CardActions, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, Grid, Hidden, Link, MenuItem, Paper, Select, Switch, Typography, useMediaQuery } from '@material-ui/core';
import { Alert, Rating } from '@material-ui/lab';
import { useTheme, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import UndoIcon from '@material-ui/icons/Undo';
import ShareIcon from '@material-ui/icons/Share';
import { generatePath, useParams, useHistory } from "react-router";

import api from '../../API';
import { APIError } from '../../API';
import AspectRatio from '../AspectRatio';
import CandidateAvatar from '../CandidateAvatar';
import FetchedContent from '../FetchedContent';
import BoldLink from '../BoldLink';
import LoadingBackdrop from '../LoadingBackdrop';
import SnackbarAlert from '../SnackbarAlert';
import VideoPlayer from '../VideoPlayer';
import utils from '../../utils';
import useIntegrations from '../../hooks/useIntegrations';
import { CopyTextLink } from '../CopyTextLink';

const ShareCandidateDialog = ({ candidateId, open, closeDialog, ...props }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [shareConfig, setShareConfig] = useState(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const sharingDetails = await api.fetchCandidateSharingDetails(candidateId);
      setShareConfig({ enabled: true, sharingDetails })
    } catch (e) {
      if (e instanceof APIError && e.statusCode() === 404) {
        setShareConfig({ enabled: false });
      } else {
        const message = e?.userMessage?.() || t('Unable to retrieve sharing details at this time. Please contact us.');
        setError(message);
      }
    }
    setLoading(false);
  }, [setError, setLoading, setShareConfig, candidateId, t]);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [fetchData, open]);

  const handleEnableSharingLinkChange = async event => {
    setError(null);
    setSaving(true);
    try {
      if (event.target.checked) {
        const sharingDetails = await api.createCandidateSharingDetails(candidateId);
        setShareConfig({ enabled: true, sharingDetails });
      } else {
        const publicCandidateId = shareConfig.sharingDetails.publicCandidateId;
        await api.deleteSharingPublicCandidateId(publicCandidateId);
        setShareConfig({ enabled: false });
      }
    } catch (e) {
      const message = e?.userMessage?.() || t('Unable to update sharing details at this time. Please contact us.');
      setError(message);
    }
    setSaving(false);
  };

  const didDataLoad = !loading && shareConfig !== null;

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={closeDialog} {...props}>
      <DialogTitle>{t('Share candidate')}</DialogTitle>
      <DialogContent>
        <FetchedContent loading={loading}>
          {
            error &&
            <Box mb={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          }
          {
            didDataLoad &&
            <>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={shareConfig.enabled}
                      onChange={handleEnableSharingLinkChange}
                      color="primary"
                      disabled={saving}
                    />
                  }
                  label={t('Enable sharing link')}
                />
              </Box>
              {
                saving &&
                <Box p={4} display="flex" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
                  <CircularProgress color="primary" />
                </Box>
              }
              {
                !saving && shareConfig.enabled &&
                <>
                  <Box mt={2}>
                    <Chip label={shareConfig.sharingDetails.publicCandidateHref} style={{ cursor: 'text', maxWidth: '100%' }} />
                    <Box mt={2}>
                      <CopyTextLink linkLabel={t("Copy sharing link")} text={shareConfig.sharingDetails.publicCandidateHref} />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="caption">
                      <Trans>
                        Share this link with individuals in your company so they can watch the interview. They don't
                        need a Canvass account to access the link. <b>Be careful <span style={{ textDecoration: 'underline' }}>not to share it publicly</span> because
                          anyone with access to the link can watch the interview.</b>
                      </Trans>
                    </Typography>
                  </Box>
                </>
              }
            </>
          }
        </FetchedContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AnswerVideo = ({ thumbnail, video, onReady }) => {
  const { t } = useTranslation();
  const { thumbnailHref, videoSources, videoSourcesSignature, captionsHref } = utils.extractHrefsForVideoPlayer(thumbnail, video);
  const videoPlayer = useMemo(() => {
    if (!thumbnailHref && !videoSources) {
      return null;
    }
    return (
      <VideoPlayer
        options={{
          controls: true,
          fluid: true,
          poster: thumbnailHref,
          sources: videoSources,
          controlBar: {
            volumePanel: false,
            pictureInPictureToggle: false,
          },
          playbackRates: [1, 1.5, 2],
        }}
        captionsHref={captionsHref}
        videoTagClassNames={['vjs-big-play-centered']}
        onReady={onReady}
      />
    );
  }, [thumbnailHref, videoSourcesSignature, captionsHref, onReady]);
  const isVideoProcessing = video.uploadStatus === 'processing';
  const isVideoCaptioning = video.captions?.captionsStatus === 'preparing';
  if (isVideoProcessing || isVideoCaptioning) {
    return (
      <AspectRatio horizontal={16} vertical={9}>
        <img src={thumbnailHref} height="100%" alt={t('Video thumbnail')} />
        <Box position="absolute" top={0} right={0} bottom={0} left={0}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            <CircularProgress size="1.5rem" />
            <Box mt={1}>
              <Typography variant="subtitle2" color="primary">
                {!isVideoProcessing && isVideoCaptioning ? t('Captioning...') : t('Processing...')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AspectRatio>
    );
  }
  return videoPlayer;
};

const MustardRating = withStyles({
  iconFilled: {
    color: '#e6ab3e',
  },
  iconHover: {
    color: '#e6ab3e',
  },
})(Rating);

const AnswerRating = ({ answer, onRatingChanged, ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [hoverRating, setHoverRating] = useState(-1);
  const [error, setError] = useState(null);
  const labels = {
    1: t('😩'),
    2: t('🙁'),
    3: t('😐'),
    4: t('🙂'),
    5: t('🤩'),
  };

  const handleChangeValue = async (event, newRating) => {
    if (!newRating) {
      // Happens when user attempts to re-rate with same rating
      // as on page load...
      return;
    }

    const oldRating = answer.rating;
    try {
      onRatingChanged(answer.answerId, newRating);
      await api.rateAnswer(answer.answerId, newRating);
    } catch (e) {
      onRatingChanged(answer.answerId, oldRating);
      setError(t('Unable to save rating. Please contact us.'));
    }
  };

  const handleChangeActive = (event, newRating) => {
    setHoverRating(newRating);
  };

  return (
    <>
      <Box display="flex" flexDirection="row-reverse" alignItems="center" p={3} {...props}>
        <Box key="result" minHeight={theme.spacing(5)}>
          {
            (hoverRating === -1 && _.isNull(answer.rating))
              ? (
                <Box fontStyle="italic" pt={1.25} pb={1.25}>
                  <Typography variant="body2">
                    {t('Not yet rated')}
                  </Typography>
                </Box>
              ) : (
                <Box fontSize="2em">
                  {labels[hoverRating !== -1 ? hoverRating : answer.rating]}
                </Box>
              )
          }
        </Box>
        <Box key="rating" flexGrow={1}>
          <MustardRating
            name={`rating-${answer.answerId}`}
            value={answer.rating}
            onChange={handleChangeValue}
            onChangeActive={handleChangeActive}
          />
        </Box>
      </Box>
      <SnackbarAlert severity="error" message={error} setMessage={setError} />
    </>
  );
};

const isAnswerPackageGenerating = answerPackage => answerPackage && answerPackage.packageStatus !== 'completed';

const ExportVideosArea = ({ answerPackage, generateAnswerPackage }) => {
  const { t } = useTranslation();

  const hasAnswerPackage = !!answerPackage;
  const isGeneratingPackage = isAnswerPackageGenerating(answerPackage);

  const handleChangeExpanded = (event, newExpanded) => {
    if (!hasAnswerPackage) {
      generateAnswerPackage();
    }
  };

  return (
    <Accordion expanded={hasAnswerPackage} onChange={handleChangeExpanded}>
      <AccordionSummary expandIcon={!hasAnswerPackage ? <ExpandMoreIcon /> : null} style={{ cursor: !hasAnswerPackage ? 'pointer' : 'default' }}>
        <Typography variant="subtitle2">
          {t('Export videos')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {
          hasAnswerPackage &&
          <Box mt={-2}>
            {
              isGeneratingPackage &&
              <Box display="flex" alignItems="center">
                <CircularProgress size="1.5em" />
                <Box ml={1.5}>
                  <Typography variant="body2">
                    {t('Generating package...')}
                  </Typography>
                </Box>
              </Box>
            }
            {
              !isGeneratingPackage &&
              <Button size="small" color="primary" startIcon={<GetAppIcon />} href={answerPackage.downloadHref} target="_blank">
                {t('Download package')}
              </Button>
            }
          </Box>
        }
      </AccordionDetails>
    </Accordion>
  );
};

const IntegrationArea = ({ candidate }) => {
  const { t } = useTranslation();
  const { getIntegrationName } = useIntegrations();

  return (
    <Accordion expanded={true}>
      <AccordionSummary expandIcon={null} style={{ cursor: 'default' }}>
        <Typography variant="subtitle2">
          {t('{{integrationName}} integration', {
            integrationName: getIntegrationName(candidate.integrationType),
          })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box mt={-2}>
          <Button size="small" color="primary" startIcon={<OpenInNewIcon />} href={candidate.integrationAttributes.profileHref} target="_blank">
            {t('Open profile')}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const CandidateEvaluation = ({ currentStatus, onClickUndo }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!['shortlisted', 'rejected'].includes(currentStatus)) {
    return null;
  }

  const displayValues = {
    'shortlisted': {
      'icon': <ThumbUpIcon />,
      'text': t('Shortlisted'),
      'color': '#076134',
    },
    'rejected': {
      'icon': <ThumbDownIcon />,
      'text': t('Rejected'),
      'color': '#ad1300',
    },
  };

  return (
    <Box
      p={2}
      display="flex"
      alignItems="center"
      style={{
        color: displayValues[currentStatus].color,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Box flexGrow={1} display="flex" alignItems="center">
        {displayValues[currentStatus].icon}
        <Box ml={1} fontSize="1.1rem">
          {displayValues[currentStatus].text}
        </Box>
      </Box>
      <Button color="primary" size="small" startIcon={<UndoIcon />} onClick={onClickUndo}>
        {t('Undo')}
      </Button>
    </Box>
  );
};

const ACTION_TO_STATUS = {
  shortlist: 'shortlisted',
  reject: 'rejected',
  undo: 'responded',
};

const computeNextToReviewId = (candidates, fromCandidate) => {
  const responded = candidates.filter(
    candidate =>
      candidate.candidateId !== fromCandidate.candidateId &&
      candidate.currentStatus === 'responded'
  );

  if (responded.length <= 0) {
    return null;
  }

  responded.sort((candidate1, candidate2) =>
    candidate1.completedAt.time > candidate2.completedAt.time ? 1 : -1
  );

  return responded[0].candidateId;
};

const CANDIDATE_REFRESH_MILLIS = 5000;
const TRANSCRIPT_REFRESH_MILLIS = 5000;

const CandidateInterview = ({ companyId, positionId, candidateId, candidates, position, candidate, setCandidate, answerTranscripts, setAnswerTranscripts, companyConfig }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { getIntegrationName } = useIntegrations();
  const history = useHistory();
  const [answerIndex, setAnswerIndex] = useState(0);
  const [saving, setSaving] = useState(false);
  const [sharingDialogOpen, setSharingDialogOpen] = useState(false);
  const [player, setPlayer] = useState(null);
  const [error, setError] = useState(null);

  const positionsHref = generatePath('/companies/:companyId/positions', { companyId });
  const positionHref = generatePath('/companies/:companyId/positions/:positionId', {
    companyId,
    positionId,
  });
  const candidateHref = generatePath('/companies/:companyId/positions/:positionId/candidates/:candidateId', {
    companyId,
    positionId,
    candidateId,
  });

  const isInvited = candidate.currentStatus === 'invited';
  const hasCompleted = !isInvited;
  const isEvaluated = hasCompleted && candidate.currentStatus !== 'responded'

  const question = candidate.questions[answerIndex];
  const answer = candidate.answers[answerIndex];
  const hasNextAnswer = answerIndex < candidate.answers.length - 1;
  const thumbnail = answer.thumbnail;
  const video = answer.video;
  const isVideoProcessing = video.uploadStatus === 'processing';
  const isCaptionsPreparing = video.captions?.captionsStatus === 'preparing';
  const isGeneratingPackage = isAnswerPackageGenerating(candidate.answerPackage);
  const shouldKeepRefreshingCandidateData = isVideoProcessing || isCaptionsPreparing || isGeneratingPackage;
  const answerTranscript = answerTranscripts[answer.answerId];
  const hasTranscript = !!answerTranscript;
  const isTranscriptProcessing = hasTranscript && ['created', 'submitted'].includes(answerTranscript.transcriptStatus);
  const isLinkOnlyCandidate = _.isUndefined(candidate.email) || _.isNull(candidate.email);

  const completedCandidates = useMemo(() => {
    const filteredCandidates = candidates.filter(
      candidate => ['responded', 'shortlisted', 'rejected'].includes(candidate.currentStatus)
    );
    filteredCandidates.sort((candidate1, candidate2) =>
      candidate1.completedAt.time > candidate2.completedAt.time ? -1 : 1
    );
    return filteredCandidates;
  }, [candidates]);
  const candidateIndex = _.findIndex(completedCandidates, candidate => candidate.candidateId === candidateId);
  const isFirstCandidate = candidateIndex <= 0;
  const isLastCandidate = candidateIndex >= completedCandidates.length - 1;
  const shouldShowIntegrationArea = candidate.integrationAttributes && candidate.integrationAttributes.profileHref;

  const generateAnswerPackage = async () => {
    const answerPackage = await api.generateAnswerPackage(candidateId);
    setCandidate(candidate => ({ ...candidate, answerPackage }));
  };

  const refreshCandidateData = useCallback(async () => {
    try {
      const candidate = await api.fetchCandidate(candidateId);
      setCandidate(candidate);
    } catch (error) {
      /* Don't need to track as error recorded on backend */
      console.log(error);
    }
  }, [candidateId, setCandidate]);

  useEffect(() => {
    let intervalId;
    if (shouldKeepRefreshingCandidateData) {
      intervalId = setInterval(refreshCandidateData, CANDIDATE_REFRESH_MILLIS);
    }
    return () => clearInterval(intervalId);
  }, [shouldKeepRefreshingCandidateData, refreshCandidateData]);

  const refreshAnswerTranscriptsData = useCallback(async () => {
    try {
      const answerTranscripts = await api.fetchCandidateAnswerTranscripts(candidateId);
      setAnswerTranscripts(answerTranscripts);
    } catch (error) {
      /* Don't need to track as error recorded on backend */
      console.log(error);
    }
  }, [candidateId, setAnswerTranscripts]);

  useEffect(() => {
    let intervalId;
    if (isTranscriptProcessing) {
      intervalId = setInterval(refreshAnswerTranscriptsData, TRANSCRIPT_REFRESH_MILLIS);
    }
    return () => clearInterval(intervalId);
  }, [isTranscriptProcessing, refreshAnswerTranscriptsData]);

  const updateCandidateStatus = async action => {
    const existingStatus = candidate.currentStatus;
    setSaving(true);
    try {
      setCandidate(candidate => ({ ...candidate, currentStatus: ACTION_TO_STATUS[action] }));
      await api.updateCandidateStatus(candidateId, action);
      setSaving(false);
    } catch (e) {
      setSaving(false);
      setCandidate(candidate => ({ ...candidate, currentStatus: existingStatus }));
      setError(t('Unable to update {{candidateName}}. Please contact us.', candidate));
    }
  };

  const goNextAnswer = () =>
    setAnswerIndex(answerIndex => Math.min(answerIndex + 1, candidate.answers.length - 1));

  const nextToReviewId = computeNextToReviewId(candidates, candidate);

  const goNextToReview = () =>
    history.push(`/companies/${companyId}/positions/${positionId}/candidates/${nextToReviewId}`);

  const handleRatingChanged = (answerId, newRating) =>
    setCandidate(candidate => ({
      ...candidate,
      answers: candidate.answers.map(answer => ({
        ...answer,
        rating: answer.answerId === answerId
          ? newRating
          : answer.rating
      }))
    }));

  const handleClickShareCandidate = () => {
    setSharingDialogOpen(true);
  };

  const answerVideo = useMemo(() =>
    <AnswerVideo thumbnail={thumbnail} video={video} onReady={setPlayer} />,
    [thumbnail, video, setPlayer]
  );

  const jumpToMillis = millis => {
    if (player) {
      player.play();
      player.currentTime(Math.floor(millis / 1000));
    }
  };

  const handleClickPreviousCandidate = () => {
    if (!isFirstCandidate) {
      const previousCandidateId = completedCandidates[candidateIndex - 1].candidateId;
      history.push(`/companies/${companyId}/positions/${positionId}/candidates/${previousCandidateId}`);
    }
  };

  const handleClickNextCandidate = () => {
    if (!isLastCandidate) {
      const nextCandidateId = completedCandidates[candidateIndex + 1].candidateId;
      history.push(`/companies/${companyId}/positions/${positionId}/candidates/${nextCandidateId}`);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" mt={3.5} mb={3.5}>
        {
          !isSmall &&
          <Box>
            <Button color="primary" size="small" startIcon={<ArrowBackIosIcon />} onClick={handleClickPreviousCandidate} disabled={isFirstCandidate}>
              {t('Previous candidate')}
            </Button>
          </Box>
        }
        <Box flexGrow={1} display="flex" justifyContent={isSmall ? "left" : "center"}>
          <Breadcrumbs>
            <Link key="positions" color="inherit" href={'#' + positionsHref}>
              {t('Positions')}
            </Link>
            <Link key="position" color="inherit" href={'#' + positionHref}>
              {position.jobTitle}
            </Link>
            <BoldLink color="inherit" href={'#' + candidateHref}>
              {candidate.candidateName}
            </BoldLink>
          </Breadcrumbs>
        </Box>
        {
          !isSmall &&
          <Box>
            <Button color="primary" size="small" endIcon={<ArrowForwardIosIcon />} onClick={handleClickNextCandidate} disabled={isLastCandidate}>
              {t('Next candidate')}
            </Button>
          </Box>
        }
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} lg={4}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center">
                {
                  candidate.thumbnail &&
                  <CandidateAvatar
                    candidateName={candidate.candidateName}
                    thumbnailUrl={candidate.thumbnail.variants[0].contentHref}
                    ml={1} mr={1} mt={1}
                  />
                }
                <Box ml={1} pt={0.7} flexGrow={1}>
                  <Typography variant="h6">
                    {candidate.candidateName}
                  </Typography>
                  <Box mt={.5}>
                    {
                      isLinkOnlyCandidate &&
                      <Box fontStyle="italic">
                        <Typography variant="caption">
                          {t("No email available")}
                        </Typography>
                      </Box>
                    }
                    {
                      !isLinkOnlyCandidate &&
                      <Link href={`mailto:${candidate.email}`}>
                        {candidate.email}
                      </Link>
                    }
                  </Box>
                  <Box mt={.7}>
                    {
                      isInvited &&
                      <Box key="created-at">
                        <Typography variant="caption">
                          {
                            !!candidate.integrationType
                              ? t('Invited {{ago}} via {{integrationName}}', {
                                integrationName: getIntegrationName(candidate.integrationType),
                                ...candidate.createdAt,
                              })
                              : t('Invited {{ago}}', candidate.createdAt)
                          }
                        </Typography>
                      </Box>
                    }
                    {
                      hasCompleted && !!candidate.integrationType &&
                      <Box key="invited-via">
                        <Typography variant="caption">
                          {t('Invited via {{integrationName}}', {
                            integrationName: getIntegrationName(candidate.integrationType),
                          })}
                        </Typography>
                      </Box>
                    }
                    {
                      candidate.integrationAttributes?.sentBy &&
                      <Box key="sent-by" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        <Typography variant="caption">
                          {t('By {{sentBy}}', { sentBy: candidate.integrationAttributes.sentBy })}
                        </Typography>
                      </Box>
                    }
                    {
                      hasCompleted &&
                      <Box key="completed-at">
                        <Typography variant="caption">
                          {t('Interviewed {{ago}}', candidate.completedAt)}
                        </Typography>
                      </Box>
                    }
                  </Box>
                </Box>
              </Box>
              {
                companyConfig.isEvaluationEnabled && isEvaluated &&
                <Box mt={3}>
                  <CandidateEvaluation currentStatus={candidate.currentStatus} onClickUndo={() => updateCandidateStatus('undo')} />
                </Box>
              }
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                size="medium"
                startIcon={<ShareIcon />}
                onClick={handleClickShareCandidate}
                style={{ marginRight: 'auto' }}
              >
                {t('Share')}
              </Button>
              {
                hasCompleted && !isEvaluated && companyConfig.isEvaluationEnabled &&
                <>
                  <Button color="primary" size="medium" startIcon={<ThumbUpIcon />} onClick={() => updateCandidateStatus('shortlist')}>
                    {t('Shortlist')}
                  </Button>
                  <Button color="primary" size="medium" startIcon={<ThumbDownIcon />} onClick={() => updateCandidateStatus('reject')}>
                    {t('Reject')}
                  </Button>
                </>
              }
              {
                isEvaluated && companyConfig.isEvaluationEnabled &&
                <>
                  <Button color="primary" size="medium" endIcon={<ArrowForwardIcon />} disabled={!nextToReviewId} onClick={goNextToReview}>
                    {t('Next to review')}
                  </Button>
                </>
              }
            </CardActions>
          </Card>
          {
            shouldShowIntegrationArea &&
            <Box mt={2}>
              <IntegrationArea candidate={candidate} />
            </Box>
          }
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <Paper>
            <Grid container>
              <Grid item xs={12} md={9}>
                <Box p={3}>
                  <FormControl fullWidth>
                    <FormHelperText>
                      {t('Answer {{num}} of {{total}}', { num: answerIndex + 1, total: candidate.answers.length })}
                    </FormHelperText>
                    <Select value={answerIndex} onChange={event => setAnswerIndex(event.target.value)}>
                      {candidate.questions.map((question, answerIndex) =>
                        <MenuItem key={answerIndex} value={answerIndex}>
                          {question.title || t('Question {{num}}', { num: answerIndex + 1 })}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Hidden smDown>
                <Grid item md={3}>
                  <Box height="100%" display="flex" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
                    <Button color="primary" size="large" endIcon={<ArrowForwardIcon />} disabled={!hasNextAnswer} onClick={goNextAnswer}>
                      {t('Next answer')}
                    </Button>
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </Paper>
          <Box mt={2}>
            <Paper style={{ overflow: 'hidden' }}>
              {
                question.content &&
                <Box p={3}>
                  <Typography key="content" variant="body2" color="textSecondary" component="p">
                    {question.content}
                  </Typography>
                </Box>
              }
              {answerVideo}
              {
                !isVideoProcessing &&
                <>
                  {
                    companyConfig.isRatingEnabled &&
                    <AnswerRating answer={answer} onRatingChanged={handleRatingChanged} />
                  }
                  {
                    hasTranscript &&
                    <>
                      {
                        companyConfig.isRatingEnabled &&
                        <Divider variant="middle" />
                      }
                      {
                        isTranscriptProcessing &&
                        <Box p={3} display="flex" alignItems="center">
                          <CircularProgress size="1.5rem" />
                          <Box ml={2}>
                            <Typography variant="subtitle1">
                              {t('Transcribing answer...')}
                            </Typography>
                          </Box>
                        </Box>
                      }
                      {
                        !isTranscriptProcessing &&
                        <Box p={3}>
                          <Typography key="title" variant="subtitle2" gutterBottom>
                            {t('Transcript')}
                          </Typography>
                          {
                            (!answerTranscript.paragraphs || answerTranscript.paragraphs.length <= 0) &&
                            <Box fontStyle="italic">
                              <Typography key="empty-transcript" variant="body2" color="textSecondary" component="p">
                                {t("No spoken words were detected")}
                              </Typography>
                            </Box>
                          }
                          {
                            answerTranscript.paragraphs &&
                            answerTranscript.paragraphs.map((paragraph, index) =>
                              <Typography key={`paragraph-${index}`} variant="body2" color="textSecondary" component="p" gutterBottom>
                                <Link onClick={() => jumpToMillis(paragraph.startMillis)} style={{ cursor: 'pointer' }}>[{utils.formatOffsetMillis(paragraph.startMillis)}]</Link>&nbsp;{paragraph.content}
                              </Typography>
                            )
                          }
                        </Box>
                      }
                    </>
                  }
                </>
              }
            </Paper>
          </Box>
          {
            isSmall &&
            <Box mt={2}>
              <ExportVideosArea answerPackage={candidate.answerPackage} generateAnswerPackage={generateAnswerPackage} />
            </Box>
          }
        </Grid>
      </Grid>
      <LoadingBackdrop open={saving} />
      <ShareCandidateDialog candidateId={candidateId} open={sharingDialogOpen} closeDialog={() => setSharingDialogOpen(false)} />
      <SnackbarAlert severity="error" message={error} setMessage={setError} />
    </>
  );
};

const CandidatePage = () => {
  const { t } = useTranslation();
  const { companyId, positionId, candidateId } = useParams();
  const [position, setPosition] = useState(null);
  const [candidates, setCandidates] = useState(null);
  const [candidate, setCandidate] = useState(null);
  const [companyConfig, setCompanyConfig] = useState(null);
  const [answerTranscripts, setAnswerTranscripts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [position, candidatesResponse, candidate, answerTranscripts, companyConfig] = await Promise.all([
        api.fetchPosition(positionId),
        api.fetchCandidates(positionId),
        api.fetchCandidate(candidateId),
        api.fetchCandidateAnswerTranscripts(candidateId),
        api.fetchCompanyConfig(companyId),
      ]);
      setPosition(position);
      setCandidates(candidatesResponse.candidates)
      setCandidate(candidate);
      setAnswerTranscripts(answerTranscripts);
      setCompanyConfig(companyConfig);
    } catch (e) {
      const message = e?.userMessage?.() || t('Unable to retrieve data at this time. Please contact us.');
      setError(message);
    }
    setLoading(false);
  }, [positionId, candidateId, companyId, setPosition, setCandidates, setCandidate, setAnswerTranscripts, setCompanyConfig, setError, t, setLoading]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading &&
    position !== null &&
    candidates !== null &&
    candidate !== null &&
    answerTranscripts !== null &&
    companyConfig !== null;

  return (
    <FetchedContent loading={loading} mt={2}>
      {error && <Alert severity="error">{error}</Alert>}
      {
        didDataLoad &&
        <CandidateInterview
          companyId={companyId}
          positionId={positionId}
          candidateId={candidateId}
          position={position}
          candidates={candidates}
          candidate={candidate}
          setCandidate={setCandidate}
          answerTranscripts={answerTranscripts}
          setAnswerTranscripts={setAnswerTranscripts}
          companyConfig={companyConfig}
        />
      }
    </FetchedContent >
  );
};

export default CandidatePage;